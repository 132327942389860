<template>
	<EditDataObatKecantikan v-if="cabang.tipe == 'kecantikan'" />
	<EditDataObatKesehatan v-else />
</template>


<script setup>
import EditDataObatKesehatan from './components/EditDataObatKesehatan.vue'
import EditDataObatKecantikan from './components/EditDataObatKecantikan.vue'

import store from '@/store'
import { computed } from 'vue'

const cabang = computed(() => store.getters['auth/cabang'])
</script>