<template>
	<div>
	<contentHeader title="Data Obat" url="/data-obat" subTitle="Edit Data Obat" />
	<div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Edit Data Obat</h3>
	            		</div>
	            		<div class="card-body row">
	            			<div class="col-lg-2"></div>
	            			<div class="col-lg-8">
	            				<form @submit.prevent="updateDataObat">
		            				<div class="px-lg-2 ">
		            					<div class="form-group">
					                		<label for="kode">Kode Obat </label>
					                		<input type="text" class="form-control" id="kode" name="kode" required="" autocomplete="off" disabled="" v-model="obat.kode">
					                	</div>
					                	<div class="form-group">
					                		<label for="nama">Nama <span class="text-danger">*</span></label>
					                		<input type="text" class="form-control" id="nama" name="nama" required="" autocomplete="off" v-model="obat.nama">
					                	</div>
					                	<div class="form-group">
					                		<label for="fungsi">Fungsi </label>
					                		<textarea class="form-control" v-model="obat.fungsi"></textarea>
					                	</div>
					                	<div class="form-group">
					                		<label for="golongan">Golongan <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.golongan" required="">
					                			<option selected="" hidden=""></option>
					                			<option value="bahan jadi">Bahan Jadi</option>
					                			<option value="bahan baku">Bahan Baku</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label >Satuan <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="obat.satuan" required="">
					                			<option selected="" hidden=""></option>
					                			<option value="pcs">Pcs</option>
					                			<option value="tube">Tube</option>
					                			<option value="ml">Ml</option>
					                			<option value="fls">Fls</option>
					                			<option value="cream">Cream</option>
					                			<option value="vial">Vial</option>
					                			<option value="bungkus">Bungkus</option>
					                			<option value="botol">Botol</option>
					                			<option value="tablet">Tablet</option>
					                			<option value="kaplet">Kaplet</option>
					                			<option value="gram">Gram</option>
					                			<option value="pot">Pot</option>
					                			<option value="strip">Strip</option>
					                			<option value="box">Box</option>
					                			<option value="ampl">Ampl</option>
					                			<option value="cc">CC</option>
					                			<option value="kotak">Kotak</option>
					                			<option value="lembar">Lembar</option>
					                			<option value="kapsul">Kapsul</option>
					                			<option value="kaleng">Kaleng</option>
					                		</select>
					                	</div>
					                	<div class="form-group" v-if="toggleInputBobot">
					                		<label for="bobot">Bobot Obat <span class="text-danger">*</span></label>
					                		<div class="row">
					                			<div class="col col-md-4">
					                				<div class="input-group mb-3">
						                				<input type="number" step="any" class="form-control" id="bobot" required="" name="bobot" v-model="obat.bobot">
						                				<div class="input-group-prepend">
										                  <div class="btn btn-secondary">Gram</div>
										                </div>
					                				</div>
					                			</div>
					                			<div class="col col-md-8"></div>
					                		</div>
					                	</div>
					                	<div class="form-group">
						                    <label>Harga <span class="text-danger">*</span></label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Harga Beli</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_beli" v-model="obat.harga_beli">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Harga Jual</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_jual" v-model="obat.harga_jual">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<div class="form-group">
					                		<div class="row">
					                			<div class="col">
					                				<label for="minimal_stok">Minimal Stok </label>
					                				<input type="number" class="form-control" id="minimal_stok" name="minimal_stok" autocomplete="off" required="" v-model="obat.minimal_stok">	
					                			</div>
					                			<div class="col">
					                				<label for="maksimal_stok">Maksimal Stok </label>
					                				<input type="number" class="form-control" id="maksimal_stok" required="" name="maksimal_stok" autocomplete="off" v-model="obat.maksimal_stok">
					                			</div>
					                		</div>
					                	</div>
					                	<div class="form-group">
					                		<label for="lokasi">Lokasi </label>
					                		<input type="text" class="form-control" id="lokasi" name="lokasi" autocomplete="off" v-model="obat.lokasi">
					                	</div>
					                	<div class="form-group">
						                    <label>Fee </label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Fee Dokter</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ fee_dokter }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_dokter" v-model="obat.fee_dokter" @keyup="feeForDokter">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Beautician</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ fee_beautician }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_beautician" v-model="obat.fee_beautician" @keyup="feeForBeautician">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Sales</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ fee_sales }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_sales" v-model="obat.fee_sales" @keyup="feeForSales">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											  <span class="sr-only">Loading...</span>
											</div>
										</button>
		            				</div>
		            			</form>
	            			</div>
	            			<div class="col-lg-2"></div>
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'

	import { onMounted, ref, computed } from 'vue'
	import axios from 'axios'
	import store from '@/store'
	import Swal from 'sweetalert2'
	import router from '@/router'
	import { useRoute } from 'vue-router'

	const route = useRoute()
    const obat_id = route.params.id
    const cabang = computed(() => store.getters['auth/cabang']) 
    const loading = ref(false)
    const obat = ref([])
    const toggleInputBobot = ref(false)
    const fee_dokter = ref('...')
    const fee_beautician = ref('...')
    const fee_sales = ref('...')

    const feeForDokter = () => {
        if (obat.value.fee_dokter == 0) {
            fee_dokter.value = '...'
        } else {
            if (obat.value.fee_dokter <= 100) {
                fee_dokter.value = 'Persen (%)'
            } else {
                fee_dokter.value = 'Rp.'
            }
        }
    }

    const feeForBeautician = () => {
        if (obat.value.fee_beautician == 0) {
            fee_beautician.value = '...'
        } else {
            if (obat.value.fee_beautician <= 100) {
                fee_beautician.value = 'Persen (%)'
            } else {
                fee_beautician.value = 'Rp.'
            }
        }
    }

    const feeForSales = () => {
        if (obat.value.fee_sales == 0) {
            fee_sales.value = '...'
        } else {
            if (obat.value.fee_sales <= 100) {
                fee_sales.value = 'Persen (%)'
            } else {
                fee_sales.value = 'Rp.'
            }
        }
    }

    const getObat = async () => {
        let { data } = await axios.get(`api/produk/getWhereId/${obat_id}/${cabang.value.tipe}`)

        obat.value = data

        if (obat.value != 'kosong') {
            feeForDokter()
            feeForBeautician()
            feeForSales()
        } 

        if (obat.value.kategori == 'RAC') {
            toggleInputBobot.value = true
        }
    }

    const updateDataObat = () => {
        if (obat.value.kategori == 'RAC') {
            obat.value.total_bobot = parseInt(obat.value.bobot) * parseInt(obat.value.stok_akhir) 
        }

        loading.value = true
        axios.put(`api/produk/updateKecantikan/${obat_id}`, obat.value)
        .then((response) => {
            Swal.fire({
                title: 'Berhasil!',
                text: 'Berhasil mengedit data',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            })	
            console.log(response.data)
            router.push('/data-obat')
        })
        .catch((error) => {
            Swal.fire({
                title: 'Gagal!',
                text: 'Gagal mengedit data',
                icon: 'error',
                confirmButtonText: 'Ok'
            })	
            console.log(error)
        })	
    }

    onMounted(() => {
        getObat()
    })
</script>